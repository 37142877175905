import { useContext, useState, useEffect } from 'react';
import FirebaseContext from '../contexts/FirebaseContext';
import { Modal } from 'antd';

export default () => {
  const [admin, setAdmin] = useState(null);
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    const handler = firebase.auth().onAuthStateChanged(async (account) => {
      try {
        if (account) {
          const token = await account.getIdTokenResult();
          if (token.claims.easybussuper) {
            setAdmin({
              name: account.displayName||'',
              email: account.email,
              id: account.uid
            });
          } else {
            firebase.auth().signOut();
            Modal.confirm({title: `Account not support!`});
          }
        } else {
          setAdmin(false);
        }
      } catch (err) {
        console.error(err);
      }
    }, error => {
      console.error(error);
      setAdmin(false);
    });

    return handler;
  }, []);

  return admin;
}