import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/storage";
import FirebaseContext from './contexts/FirebaseContext';
import {
  BrowserRouter as Router,
} from "react-router-dom";

firebase.initializeApp({
  apiKey: "AIzaSyCOfdGYKpcJ4R_u8RqCSG5wXWXnjPpsZGE",
  authDomain: "easybus-clean-cloud.firebaseapp.com",
  databaseURL: "https://easybus-clean-cloud.firebaseio.com",
  projectId: "easybus-clean-cloud",
  storageBucket: "gs://easybus-img",
  messagingSenderId: "628489811457",
  appId: "1:628489811457:web:187398e839fb620b"
});

ReactDOM.render(
  <Router>
    <FirebaseContext.Provider value={firebase}>
      <App/>
    </FirebaseContext.Provider>
  </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
