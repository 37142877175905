import React, { lazy, Suspense } from 'react';
import './App.css';
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Avatar, Layout, List, Menu, Modal, Tooltip } from 'antd';
import { TeamOutlined, CreditCardOutlined, NotificationOutlined, FilePdfOutlined } from '@ant-design/icons';
import ErrorBoundary from './components/ErrorBoundary';
import useSignInAccount from './hooks/useSignInAccount';
import AdminContext from './contexts/AdminContext';
import OperatorSignInPanel from './components/OperatorSignInPanel';
import { EASYBUS_PRODUCT_ID_FACTORY, EASYBUS_PRODUCT_ID_ONLINE, EASYBUS_PRODUCT_ID_POS, EASYBUS_PRODUCT_ID_WORKFLOW } from './constants/platform';
import { BUSINESS_SECTOR_AIRCON_SERVICE, BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_BEAUTY_SHOP, BUSINESS_SECTOR_FLOWER, BUSINESS_SECTOR_HAIR_SALON, BUSINESS_SECTOR_HANDYMAN, BUSINESS_SECTOR_HOME_CLEANING, BUSINESS_SECTOR_LAUNDRY, BUSINESS_SECTOR_MASSAGE, BUSINESS_SECTOR_PEST_CONTROL, BUSINESS_SECTOR_YOGA_FITNESS } from './constants/business';
const { Content, Sider } = Layout;

const HomePage = lazy(()=>import('./HomePage'));
const DeploymentsPage = lazy(()=>import('./DeploymentsPage'));
const AdminPage = lazy(()=>import('./AdminPage'));
const ProductPage = lazy(()=>import('./ProductPage'));
const BusinessTempaltePage = lazy(()=>import('./BusinessTempaltePage'));
const PaymentMethodsPage = lazy(()=>import('./PaymentMethodsPage'));
const SystemNoticePage = lazy(()=>import('./SystemNoticePage'));
const OutstandingInvoicesPage = lazy(()=>import('./OutstandingInvoicesPage'));

export default () => {
  const history = useHistory();
  const location = useLocation();
  const account = useSignInAccount();

  return (
    <ErrorBoundary>
      <AdminContext.Provider value={account}>
        <Layout style={{backgroundColor:'white'}}>
          <Sider theme='light' style={{
              height: '100vh',
              position: 'fixed',
              left: 0,
              borderRight: 1
            }}
          >
            <div className='flex full-height' style={{position: 'relative', overflow: 'auto'}}>
              <Tooltip title={'Home'} placement='right'>
                <div className='padding-half' onClick={()=>history.push('/')}>
                  <img style={{height:40}} src={'/easybus.png'} />
                </div>
              </Tooltip>
              <Menu mode="inline" onSelect={({key}) => history.push(key)} selectedKeys={[location.pathname]}>
                <Menu.Item key={`/customers`}><TeamOutlined /> <span>Customers</span> </Menu.Item>
                <Menu.Item key={`/notice`}><NotificationOutlined /> <span>System notice</span> </Menu.Item>
                <Menu.Item key={`/outstandings`}><FilePdfOutlined /> <span>Outstanding invoice</span> </Menu.Item>
                <Menu.ItemGroup title='Platform Settings'>
                  <Menu.Item key={`/settings/payment_methods`}><CreditCardOutlined /> <span>Payment methods</span> </Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title='Platform Pricings'>
                  <Menu.Item key={`/pricings/${EASYBUS_PRODUCT_ID_POS}`}><span>EasyBus Cloud POS</span> </Menu.Item>
                  <Menu.Item key={`/pricings/${EASYBUS_PRODUCT_ID_ONLINE}`}><span>EasyBus Web App</span> </Menu.Item>
                  <Menu.Item key={`/pricings/${EASYBUS_PRODUCT_ID_WORKFLOW}`}><span>EasyBus Workflow</span> </Menu.Item>
                  <Menu.Item key={`/pricings/${EASYBUS_PRODUCT_ID_FACTORY}`}><span>EasyBus Plant</span> </Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title='Business Templates'>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_LAUNDRY}`}><span>Laundry</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_ALTERATION}`}><span>Alteration</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_BAG_SERVICE}`}><span>Bag service</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_AIRCON_SERVICE}`}><span>Aricon servicing</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_HOME_CLEANING}`}><span>Home/Office Cleaning</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_HAIR_SALON}`}><span>Hair salon</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_MASSAGE}`}><span>Massage</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_BEAUTY_SHOP}`}><span>Beauty shop</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_YOGA_FITNESS}`}><span>Yoga & Fitness</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_FLOWER}`}><span>Flower</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_PEST_CONTROL}`}><span>Pest control</span> </Menu.Item>
                  <Menu.Item key={`/businesses/${BUSINESS_SECTOR_HANDYMAN}`}><span>Handyman</span> </Menu.Item>
                </Menu.ItemGroup>
              </Menu>
              <br/><br/><br/><br/>
              {account&&<Avatar size='large' style={{position: 'fixed', left: 20, bottom: 20,
                backgroundColor: '#1890ff', cursor: 'pointer'}} onClick={()=>history.push(`/admin`)}>{`${account.name||''}`.slice(0,5)}</Avatar>}
            </div>
          </Sider>
          <Layout style={{ marginLeft: 200, backgroundColor: 'white' }}>
            <Content>
              <Suspense fallback={<List loading/>}>
                <Switch>
                  <Route path='/customers' exact><DeploymentsPage/></Route>
                  <Route path='/notice' exact><SystemNoticePage/></Route>
                  <Route path='/outstandings' exact><OutstandingInvoicesPage/></Route>
                  <Route path='/settings/payment_methods' exact><PaymentMethodsPage/></Route>
                  <Route path='/pricings/:productId'><ProductPage/></Route>
                  <Route path='/businesses/:sector'><BusinessTempaltePage/></Route>
                  <Route path='/' exact><HomePage/></Route>
                  {account&&<Route path='/admin' exact><AdminPage/></Route>}
                  <Redirect to='/' exact/>
                </Switch>
              </Suspense>
            </Content>
          </Layout>
        </Layout>
        <Modal footer={false} visible={!account}>
          {(account===null)&&<List loading/>}
          {(account===false)&&<OperatorSignInPanel/>}
        </Modal>
      </AdminContext.Provider>
    </ErrorBoundary>
  );
}