import { Button, Divider, Input, message } from 'antd';
import React, { useContext, useState } from 'react';
import FirebaseContext from '../contexts/FirebaseContext';

export default () => {
  const firebase = useContext(FirebaseContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [processing, setProcessing] = useState(false);

  const onSignIn = async () => {
    if (!email||!password) return;

    try {
      setProcessing(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      message.error(err.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <React.Fragment>
      <Divider>Sign In</Divider>
      
        <Input value={email} onChange={e=>setEmail(e.target.value)}/>
        <br/><br/>
        <Input.Password value={password} onChange={e=>setPassword(e.target.value)}/>
        <br/><br/>
        <Button type='primary' block loading={processing} onClick={onSignIn}>SIGN IN</Button>
      
    </React.Fragment>
  )
};