export const PLATFORM_OPERTION_TOGGLE_PRICE = 'toggle price';
export const PLATFORM_OPERTION_UPDATE_LICENCE = 'update licence';
export const PLATFORM_OPERTION_ACCESS_REALM = 'access realm';
export const PLATFORM_OPERTION_UPDATE_BUSINESS_TEMPLATE = 'update business template';
export const PLATFORM_OPERTION_EDIT_PAYMENT_METHOD = 'edit payment method';
export const PLATFORM_OPERTION_NEW_NOTICE = 'new notice';
export const PLATFORM_OPERTION_REVALIDATE_WEB_APPS = 'revalidate web app';
export const PLATFORM_NOTICE_DUE_INVOICE = 'notice due invoice';

export const EASYBUS_PRODUCT_ID_POS = 'POS';
export const EASYBUS_PRODUCT_ID_ONLINE = 'ONLINE';
export const EASYBUS_PRODUCT_ID_FACTORY = 'FACTORY';
export const EASYBUS_PRODUCT_ID_WORKFLOW = 'WORKFLOW';

export const PRICE_STATUS_PUBLIC = 'public';
export const PRICE_STATUS_PRIVATE = 'private';

export const CLIENT_WEB = 'web';
export const CLIENT_POS = 'pos';
export const CLIENT_IOS = 'ios';
export const CLIENT_ANDROID = 'android';
export const CLIENT_MOBILE = 'mobile';
export const CLIENT_CONSOLE = 'console';