import React from 'react';
import { Alert } from "antd";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    if (error&&error.message&&(error.message.indexOf('Loading chunk')>=0)) {
      window.location.reload(true);
    } else {
      return { hasError: true, errorMsg: error&&error.message };
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='padding'>
          <Alert banner showIcon type='error' message={'ERROR'}
            description={this.state.errorMsg} closeText='Reload' onClose={()=>{
              window.location.reload(true);
            }}/>
        </div>
      );
    }

    return this.props.children; 
  }
}